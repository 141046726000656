import smu from "../images/smu_logo.jpg"
import win33 from "../images/win33.png"
import React from "react"

const Awards = () => {
  return (
    <>
      <h1 className="award-title title--center">Awards</h1>
      <div
        className="row no-reverse row--center"
        style={{ flexDirection: "row" }}
      >
        <div className="col col__3  col__m3 col__s6 col__xs12">
          <div className="row no-reverse row--center">
            <img
              src={smu}
              alt="award smu"
              title="SMU Dallas 100 - One of the 100 fastest growing companies in Dallas, 2022 and 2023"
              style={{ maxHeight: 112.5 }}
            />

            <div
              style={{
                fontSize: "12px",
                marginTop: "15px",
                textAlign: "center",
              }}
            >
              <p>
                <b>SMU Dallas 100</b> - One of the 100 fastest growing companies
                in Dallas, 2022 and 2023
              </p>
            </div>
          </div>
        </div>
        <div className="col col__3 col__m3 col__s6 col__xs12">
          <div className="row no-reverse row--center">
            <img
              src={win33}
              alt="award win33"
              title="Win33 - One of the 33 fastest growing startups in Guatemala, 2021"
              style={{ maxHeight: 112.5 }}
            />
            <div
              style={{
                fontSize: "12px",
                marginTop: "15px",
                textAlign: "center",
              }}
            >
              <p>
                <b>Win33</b> - One of the 33 fastest growing startups in
                Guatemala, 2021
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Awards
